import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'

const Terms = () => (
  <Layout>
    <SEO title="Terms and Conditions" />
    <article>
      <div className="c-content">
        <div className="container">
          <div className="row">
            <div className="c-content__content-container">
              <div className="row">
                <div className="c-content__title-container">
                  <h2 className="c-content__title">Terms and Conditions</h2>
                </div>
                <div className="c-content__summary-container cms-content">
                  <ul>
                    <li>Competition details form part of these terms and conditions</li>
                    <li>Entry is open to residents of the UK.</li>
                    <li>The competition will run from 10th July to 15th July 2019. A winner will be selected from entries submitted (in the format detailed on each social platform).</li>
                    <li>The entrant(s) must be aged 18 or over.</li>
                    <li>Proof of identity and age may be required.</li>
                    <li>Use of a false name or address will result in disqualification.</li>
                    <li>All entries must be made directly by the person entering the competition</li>
                    <li>Entries made online using methods generated by a script, macro or the use of automated devices will be void.</li>
                    <li>No responsibility can be accepted for entries lost, delayed or corrupted, or due to computer error in transit</li>
                    <li>The prizes are as stated, are not transferable to another individual and no cash or other alternatives will be offered</li>
                    <li>The winner(s) is(are) responsible for expenses and arrangements not specifically included in the prizes, including any necessary travel</li>
                    <li>Prizes are subject to availability</li>
                    <li>The promoters reserve the right to amend or alter the terms of competitions and reject entries from entrants not entering into the spirit of the competition</li>
                    <li>In the event of a prize being unavailable, the promoter reserves the right to offer an alternative prize of equal or greater value</li>
                    <li>The winner(s) agree(s) to the use of their name, photograph and disclosure of county of residence and will cooperate with any other reasonable requests
                      by The NOAH MEDIA GROUP LTD relating to any post-winning publicity</li>
                    <li>Unless stated otherwise the winner(s) will be drawn at random from all correct entries received by the closing date stated within the promotional material</li>
                    <li>Reasonable efforts will be made to contact the winner(s). If the winner(s) cannot be contacted, or are unable to comply with these terms and conditions,
                      the Promoter reserves the right to offer the prize to the next eligible entrant drawn at random</li>
                    <li>Confirmation of the prize will also be made in writing to the winner(s).</li>
                    <li>Failure to respond and/or provide an address for delivery, or failure to meet the eligibility requirements may result in forfeiture of the prize.</li>
                    <li>Where applicable, the decision of the judges is final based on the criteria set out in the promotion and no correspondence will be entered into over this decision.
                      Competitions may be modified or withdrawn at any time</li>
                    <li>The Promoter is The Noah Media Group Ltd, 32 Clerkenwell Green, London, EC1R 0DU</li>
                    <li>In the event of a discrepancy between these standard terms and conditions and the details in the promotional material (or any other terms and conditions provided/referred
                      to at the time of entry), the details of the promotional material (and any other terms and conditions provided/referred to at the time of entry) shall prevail</li>
                    <li>A list of winners will be available by writing into the promoter at the Promoter's address no earlier than six weeks (unless otherwise stated) after the close of
                      the competition. Only the surname and county of the winners will be disclosed.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </Layout>
)

export default Terms
